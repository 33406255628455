module.exports = {
  actions: 'Actions',
  addConnector: 'Ajouter un connecteur',
  amazonWebServicesDescription:
    'Importer les données de facturation de votre compte AWS.',
  amazonWebServicesDescriptionDelete:
    'Supprimer la connexion à votre compte AWS.',
  amazonWebServicesDescriptionEdit:
    'Modifier les informations de connexions à votre compte AWS.',
  article: 'Article',
  authenticating: 'Connexion',
  authenticating_success: 'Connexion réussie',
  available: 'Disponible',
  availableSoon: 'Bientôt disponible',
  awsBill: 'Facture Amazon AWS',
  awsFullName: 'Amazon Web Services',
  azureFullName: 'Microsot Azure',
  bill: 'Facture',
  billed_period: 'Période facturée',
  categories: 'Catégories',
  checkTheFollowingCheckboxes: 'Cocher les cases suivantes :',
  company: 'Entreprise',
  connectorList: 'Liste de vos connecteurs',
  userList: 'Liste de vos utilisateurs',
  reportList: 'Liste de vos imports',
  viewerList: 'Liste des lecteurs',
  connector_list: 'Connecteur',
  connectorsListCreatedAt: 'Crée le : ',
  connectorsListDelete: 'Supprimer le connecteur',
  connectorsListEdit: 'Editer le titre',
  connectorsListProvider: 'Fournisseur : ',
  cost: 'Coût HT',
  costDistribution: 'Répartition des coûts',
  costPerProvider: 'Coût par fournisseur',
  currentMonth: 'Mois en cours',
  currentMonthEstimation: 'Mois en cours',
  currentYear: 'Année en cours',
  date: 'Date',
  default: "Texte affiché par défaut quand rien d'autre n'est spécifié",
  deleteConnector: 'Supprimer un connecteur',
  doing: 'En cours...',
  editConnector: 'Modifier un connecteur',
  editMyProfile: 'Editer mon profil',
  emailValidationSubTitle:
    "Rendez-vous dans votre boîte mail afin d'y obtenir votre code d'activation.",
  emailValidationTitle:
    "Nous avons envoyé un email à l'adresse que vous nous avez communiqué :",
  endDate: 'Date de fin',
  expenseEstimation: 'Estimation des dépenses',
  explorerInfoHidden:
    'Pour votre confort, nous avons masqués les dépenses inférieures à 1% du total des dépenses.',
  explorerDialogTitle: 'Détails de la ressource',
  forLastXDaysEnd: ' derniers jours',
  forLastXDaysStart: 'les ',
  from: 'Du',
  gcpFullName: 'Google Cloud Platform',
  googleCloudPlatformDescription:
    'Importer les données de facturation de votre compte GCP.',
  identity: 'Identité',
  invoiceId: 'Numéro de facture',
  itemsPerPage: 'Lignes',
  itemsPerPageAll: 'Tout',
  lastImportDayIs: 'Dernier import réalisé : ',
  lastMonth: 'Mois dernier',
  logout: 'Se déconnecter',
  microsoftAzureDescription:
    'Importer les données de facturation de votre compte Azure.',
  oracleDescription:
    'Importer les données de facturation de votre compte Oracle.',
  myAccount: 'Mon compte',
  nextMonthEstimation: 'Prochain mois',
  newScope: 'Nouveau filtre',
  loadScope: 'Charger un filtre',
  noDataText: 'Pas de données à afficher',
  numberOfConnectors: 'Nombre de connecteurs : ',
  numberOfImports: "Nombre d'imports réalisés : ",
  of: 'de',
  options: 'Options',
  ourBlog: 'Blog Lota.cloud',
  passwordForgotten: "Je n'arrive pas à me connecter",
  paymentInfo: 'Afficher mon mode de paiement',
  pleaseCreateYourAccount: 'Bienvenue, veuillez créer votre compte',
  pleaseLogin: 'Bienvenue, veuillez vous connecter',
  successNewPassword:
    'Votre mot de passe à bien été mis à jour, veuillez vous connecter.',
  pleaseSetupYourCompany:
    'Veuillez renseigner les informations de votre entreprise',
  productAndCategories: 'Produits & Catégories',
  products: 'Produits',
  profile: 'Profil',
  provider: 'Fournisseur',
  resourceUsage: 'Consommations',
  seeMyBills: 'Consulter mes factures',
  startDate: 'Date de début',
  tags: 'Étiquettes',
  to: 'au',
  topCategories: 'Top 5 catégories',
  topCategoriesShortened: 'Top catégories',
  topProducts: 'Top 5 produits',
  topProductsShortened: 'Top produits',
  total: 'Total',
  totalExpenses: 'Dépenses totales',
  totalExpensesPerProvider: 'Dépenses totales par fournisseur',
  user_list: 'Utilisateur',
  canView: 'Accès en lecture',
  providerTotalCost: 'Dépenses par fournisseur',
  top5Expenses: 'Top 5 dépenses',
  show: 'Voir',
  inExplorer: "dans l'Explorateur",
  inTagExplorer: "dans l'Explorateur de tags",
  allExpenses: 'toutes les dépenses',
  represents: 'représente',
  ofTheTotalCostTop: 'du coût total de vos 5',
  mostExpensive: 'les plus coûteux(ses).',
  categoryTop: 'catégories',
  productTop: 'produits',
  connector: {
    aws: {
      accessKeyDescription:
        "Saisissez les informations de connexion de votre l'utilisateur IAM que nous venons de créer",
      accessKeyTitle: "Clefs d'accès API",
      accessTypeDescription:
        "Sélectionner 'Accès par programmation' puis cliquer sur 'Suivant'. Dans 'Autorisations', cliquer sur 'Attacher directement les stratégies existantes'.",
      accessTypeTitle: "Type d'accès",
      activateCostAllocationTagsTitle: 'Activer les balises',
      activeCostAllocationTagsDescription:
        "Aller dans la page 'Balises de répartition de coûts' et cliquer sur 'Charger vos balises' puis 'Sélectionner' et 'Activer'.",
      associateStrategyDescription:
        "Associer l'utilisateur 'apilota' à la stratégie 'accessapilota'",
      associateStrategyTitle: "Associer la stratégie à l'utilisateur",
      bucketDescription:
        'Créer un Bucket S3 pour sauvegarder vos rapports de facturation',
      bucketTitle: 'Enregistrer votre rapport sur S3',
      checkboxAutoUpdateReport:
        'Actualiser automatiquement votre rapport de coûts quand des frais de mois précédents liés à des factures clotûrées sont détéctés',
      checkboxConfirmStrategy: "J'ai confirmé que cette stratégie est correcte",
      checkboxIncludeResourceId: 'Inclure les ID de ressources',
      configureBucketDescription:
        'Nous utiliserons ce compartiment pour accéder à vos rapports de facturation',
      configureBucketTitle: 'Configurer les options de distributions',
      confirmStrategyTitle: 'Vérifier la stratégie',
      connectorNameDescription:
        "Nous utiliserons ce nom en interne pour vous permettre une meilleur visibilité. Cela n'impactera pas votre compte AWS.",
      connectorNameTitle: 'Nommer votre connector',
      costAllocationTagsDescription:
        "Les balises de répartiton de coûts permettent d'identifier l'origine des dépenses plus facilement",
      costAllocationTagsLink: 'PAGE BALISES DE REPARTITION DE COÛTS',
      costAllocationTagsTitle: 'Activer les balises de répartitions de coûts',
      createIamRoleDescription:
        "Dans l'onglet 'IAM', aller dans 'Utilisateurs' et cliquer sur 'Ajouter'.",
      createIamRoleLink: 'PAGE UTILISATEURS IAM',
      createIamRoleTitle: 'Créer un rôle IAM',
      createIamStrategyDescription:
        "Cliquer sur 'Créer une stratégie' en format JSON. Copier-coller le code suivant :",
      createIamStrategyTitle: "Créer une stratégie d'utilisateur IAM",
      createS3BucketDescription:
        'Créer un compartiment S3 et le nommer de la manière suivante : ',
      createS3BucketTitle: 'Créer un Bucket S3',
      defineS3RegionDescription:
        'Choisir une région (nous vous recommandons de choisir la région US East (Virginia) qui est la moins chère)',
      defineS3RegionTitle: 'Définir une région',
      fail: 'Connecteur créé et test échoué. Veuillez vérifier les informations saisies.',
      iamDescription:
        "Cet utilisateur va nous permettre de lire vos données de facturation et d'usage via les APIs AWS",
      iamRoleDescription:
        'Cet utilisateur va nous permettre de se connecter au compartiment S3 et de lire vos rapports de facturation',
      iamRoleNameDescription:
        "Nommer l'utilisateur IAM de la façon suivante : apilota",
      iamRoleNameTitle: "Nom de l'utilisateur",
      iamRoleTitle: 'Créer un rôle IAM',
      iamTitle: "Création d'un utilisateur IAM",
      reportDescription:
        'Générer un rapport de facturation Cost and Usage afin de nous permettre de lire vos informations de facturation',
      reportDocDescription:
        "Pour créer un rapport de facturation, se rendre dans la section 'Facturation' et cliquer sur 'Cost&Usage Reports'. Cliquer ensuite sur 'créer un rapport'. Nommer le rapport de la façon suivante : ",
      reportDocTitle: 'Créer un rapport Cost & Usage',
      reportLinkTitle: 'PAGE COST&USAGE REPORTS',
      reportTitle: 'Créer un rapport de facturation AWS',
      strategyNameDescription:
        'Nommer la stratégie de la façon suivante : accessapilota',
      strategyNameTitle: 'Nom de la stratégie',
      success:
        'Connecteur créé et testé avec succès. Nous vous enverrons un email pour vous notifier du premier import de données.',
      verifyYourConnector:
        'Nous devons vérifier les informations de connexions de votre nouveau connecteur AWS. Cela nous permet de garantir que nous aurons accès aux rapports de facturation une fois ceux-ci générés par Amazon.',
    },
  },
  created_by: 'Créé par ',
  on: ' le ',
  dataHostingIn: 'Données hébergées en France à',
  dataHostingCity: 'Paris',
  dataHostingProvider: '(PAR-3, Scaleway)',
}
