import md5 from 'md5'

export async function useHasGravatar(email) {
  const md5Hash = md5(email.trim().toLowerCase())
  const gravatarUrl = `https://www.gravatar.com/avatar/${md5Hash}?d=404`

  try {
    const response = await fetch(gravatarUrl, { method: 'GET' })
    return response.ok
  } catch (error) {
    return false
  }
}
