module.exports = {
  active: 'Offre actuellement active',
  myCompany: 'Mon entreprise',
  myConnectors: 'Mes connecteurs',
  myUsers: 'Mes utilisateurs',
  myIntegrations: 'Mes intégrations',
  myData: 'Mes données',
  myApplication: 'Mon Application',
  myCategories: 'Mes catégories',
  myPlan: 'Mon offre',
  myPayments: 'Mes paiements',
  adminUserMenuTitle: 'Administrateur',
  download: 'Téléchargement',
  normalize: 'Normalisation',
  aggregate: 'Agrégation',
  upToDate: 'Données à jour',
  dataError: 'Une erreur est survenue ',
  contactSupport: 'Merci de contacter le support',
  error: 'Erreur',
  inProgress: 'En cours',
  licenses: 'Licences',
  licensesUsed: 'licences utilisées',
  neverSeen: 'Jamais vu',
  setUserInformation: "Saissez les informations de l'utilisateur",
  userInformationDescription:
    "Entrez les informations nécessaires pour l'utilisateur que vous souhaitez inviter à collaborer avec vous. Celui-ci reverra un mail pour créer un mot de passe.",
  assignLicense: 'Assignez une licence',
  assignLicenseDescription:
    'Sélectionnez le type de licence que vous souhaitez assigner à cet utilisateur. Les permissions des utilisateurs ayant une licence "Control" sont modulables.',
  chooseALicense: 'Choisissez une licence',
  noLicenses:
    "Aucune licence n'est provisionnée, veuillez contacter le support.",
  assignPermissionSet: 'Assignez un ensemble de permissions',
  assignPermissionSetDescription:
    'Sélectionnez un ensemble de permissions que vous souhaitez assigner à cet utilisateur. Seuls les utilisateurs ayant une licence "Control" peuvent avoir des droits différents. Le rôle Editor permet de créer et de modifier certaines ressources comme les budgets et recommandations, ces actions ne sont pas forcément autorisées avec un ensemble de permissions User.',
  chooseARole: 'Choisissez un rôle',
  validate: 'Valider',
  save: 'Enregistrer',
  close: 'Fermer',
  slotsAvailable: 'places disponibles',
  userProfile: 'Profil utilisateur',
  resetPassword: 'Réinitialiser le mot de passe',
  deleteUser: "Supprimer l'utilisateur",
  permissions: 'Permissions',
  permissionsDescription:
    "Vous pouvez assigner un ensemble de permissions à cet utilisateur. Selon l'ensemble de permissions choisi, l'utilisateur aura accès des certaines ressources sur l'application. Seuls les utilisateurs ayant une licence 'Control' peuvent avoir des droits différents.",
  availableRoles: 'Rôles disponibles:',
  userRole:
    'User: rôle par défaut pour les nouveaux utilisateurs ayant une licence "Control", autorise l\'accès à la majorité des ressources, mais restreint les modifications.',
  editorRole:
    'Editor: autorise la modification et la création de nouvelles ressources, tel que les budgets ou les recommandations.',
  unauthorizedRoles:
    'Cet utilisateur ne peut pas avoir des permissions spéciales.',
  deleteAccount: 'Supprimer ce compte?',
  deleteAccountDescription:
    'Êtes-vous sûr de vouloir supprimer ce compte ? Cette action est irréversible.',
  delete: 'Supprimer',
  profileUpdated: 'Profil mis à jour',
  permissionsUpdated: 'Permissions mises à jour',
  passwordResetSent:
    "Un nouveau mot de passe a été envoyé à l'adresse email associée.",
  userDeleted: "L'utilisateur a été supprimé",
  myFilters: 'Mes filtres',
  filtersListTitle: 'Liste de vos filtres',
  manageFilters: 'Gérer mes filtres',
  filtersListDescription:
    'Dans cette section vous pouvez facilement gérer ou supprimer vos filtres',
  searchFilter: 'Rechercher un filtre',
  integrationsTitle: 'Intégrations & Notifications',
  integrationsSubtitle:
    'Configurez vos intégrations pour recevoir des notifications sur vos outils de communication.',
  createIntegrationTitle: 'Créer une intégration',
  createIntegrationSubtitle:
    'Connectez notre application avec votre environnement de travail.',
  notificationsTitle: 'Notifications',
  notificationsSubtitle:
    'Configurez les notifications que vous souhaitez recevoir sur vos outils de communication.',
  searchNotificationsPlaceholder: 'Chercher une notification',
  noNotifications: "Aucune notification n'est disponible",
  noNotificationsResult: 'Aucune notification trouvée',
  integrationsFooterText: 'Vous souhaitez-vous une nouvelle intégration ?',
  settingsApplicationTitle: "Configuration de l'application",
  settingsApplicationSubtitle:
    'Activez vos étiquettes de facturation et configurez vos catégorie pour améliorer vos rapports.',
  tagsConfigurationSubtitle:
    'Activez vos étiquettes FinOps pour les utiliser dans notre application.',
  categoriesConfigurationSubtitle:
    'Créez des catégories personnalisées et associez-y vos produits pour personnaliser vos rapports.',
  applicationFooterTitle: 'Vous avez effectué une modification ?',
  applicationFooterSubtitle:
    "Vos données seront automatiquement rafraîchies dans les 30 prochaines minutes pour permettre d'intégrer vos changements.",
  settingsConnectorsTitle: 'Mes connecteurs',
  settingsConnectorsSubtitle:
    'Ajoutez et configurez la connexion aux différents fournisseurs.',
  settingsNewConnectorTitle: 'Créez une nouvelle connexion',
  settingsNewConnectorSubtitle:
    'Connectez votre Fournisseur à notre application.',
  connectorManagementTitle: 'Gérez vos connecteurs',
  connectorManagementSubtitle:
    'Modifiez ou supprimez vos connecteurs existants.',
  settingsDataSubTitle:
    'Consulter la liste des imports de données réalisés sur ce compte.',
  scopeTitle: 'Mes filtres',
  scopeSubtitle: "Consulter, éditer et supprimer des filtres de l'application.",
  profileTitle: 'Mon profil',
  profileSubtitle: "Configurez votre profil d'utilisateur & d'entreprise.",
  jiraSetupTitle: "Configuration de l'intégration Jira",
  jiraSetupSubtitle:
    "Connectez vos projets Jira avec notre outil pour partager des recommandations et bien d'autres.",
  jiraProjectIntegrationsTitle: 'Mes projets Jira',
  jiraProjectIntegrationsSubtitle:
    'Liste des projets qui sont connectés avec notre application.',
  jiraInstallationDocumentationTitle: 'Intégrer un projet Jira',
  jiraInstallationDocumentationSubtitle:
    "L'intégration Jira avec notre solution permet de dispatcher les recommandations directement auprès des équipes en charge de les appliquer.",
  jiraWorkflowDocumentationTitle: 'Comment ça marche ?',
  jiraWorkflowDocumentationSubtitle:
    'Comprenez les mécanismes et les intéractions de notre application avec vos projets Jira.',
  projectKey: 'Clé du projet',
  projectName: 'Nom du projet',
  createdDate: 'Date de création',
}
