import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=67e2f8bf&scoped=true"
import script from "./UserProfile.vue?vue&type=script&setup=true&lang=js"
export * from "./UserProfile.vue?vue&type=script&setup=true&lang=js"
import style0 from "./UserProfile.vue?vue&type=style&index=0&id=67e2f8bf&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67e2f8bf",
  null
  
)

export default component.exports