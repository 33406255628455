<template>
  <div class="logo-container">
    <img
      :src="logo"
      class="team-icon"
      :style="{ maxHeight: maxHeight }"
      alt="logo"
    />
  </div>
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue'

const instance = getCurrentInstance().proxy
const store = computed(() => instance.$store)

const logo = computed(
  () =>
    store.value.getters.company.logo || require('@/assets/image/lota_logo.png'),
)

const props = defineProps({
  maxHeight: {
    type: String,
    default: '60px',
  },
})
</script>

<style scoped>
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.team-icon {
  border-radius: 4px;
  object-fit: cover;
}

.v-tooltip__content {
  font-size: 12px !important;
  padding: 4px 8px !important;
  font-weight: bold !important;
}
</style>
