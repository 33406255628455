export function useColor() {
  // Function to lighten hex colors
  const lightenHexColor = (hex, percent) => {
    let r = parseInt(hex.slice(1, 3), 16)
    let g = parseInt(hex.slice(3, 5), 16)
    let b = parseInt(hex.slice(5, 7), 16)

    r = Math.min(255, Math.floor(r + (255 - r) * percent))
    g = Math.min(255, Math.floor(g + (255 - g) * percent))
    b = Math.min(255, Math.floor(b + (255 - b) * percent))

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
  }

  // Function to generate a gradient of colors
  const generateGradient = (baseColor, steps) => {
    if (steps <= 1) return [baseColor]
    if (steps === 2) return [baseColor, lightenHexColor(baseColor, 0.3)]

    const gradientColors = []
    const stepSize = 1 / (steps - 1)

    for (let i = 0; i < steps; i++) {
      const percent = stepSize * i
      gradientColors.push(lightenHexColor(baseColor, percent))
    }

    return gradientColors
  }

  // Function to interpolate between two colors
  const interpolateColor = (color1, color2, factor) => {
    const hexToRgb = (hex) => {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return { r, g, b }
    }

    const rgbToHex = (r, g, b) => {
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
    }

    const rgb1 = hexToRgb(color1)
    const rgb2 = hexToRgb(color2)

    const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * factor)
    const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * factor)
    const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * factor)

    return rgbToHex(r, g, b)
  }

  const generateGradientBetweenColors = (color1, color2, steps) => {
    const gradientColors = []
    const stepSize = 1 / (steps - 1)

    for (let i = 0; i < steps; i++) {
      const factor = stepSize * i
      gradientColors.push(interpolateColor(color1, color2, factor))
    }

    return gradientColors
  }

  return { lightenHexColor, generateGradient, generateGradientBetweenColors }
}
