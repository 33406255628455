export const settingRoutes = [
  {
    path: 'settings',
    component: () => import('@/views/SettingsView.vue'),
    children: [
      {
        path: 'company',
        name: 'settings',
        component: () => import('@/components/settings/SettingCompany.vue'),
      },
      {
        path: 'connector',
        name: 'settings-connectors',
        meta: { permission: 'connector.index' },
        component: () => import('@/components/settings/SettingConnector.vue'),
      },
      {
        path: 'connector/aws',
        name: 'aws-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('@/components/settings/connectors/AWS/AwsCreate.vue'),
      },
      {
        path: 'connector/oracle',
        name: 'oracle-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('@/components/settings/connectors/oracle/OracleCreate.vue'),
      },
      {
        path: 'connector/azure',
        name: 'azure-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('@/components/settings/connectors/azure/AzureCreate.vue'),
      },
      {
        path: 'connector/gcp',
        name: 'gcp-create',
        meta: { permission: 'connector.index' },
        component: () =>
          import('@/components/settings/connectors/GCP/GcpCreate.vue'),
      },
      {
        path: 'users',
        name: 'settings-user',
        meta: { permission: 'user.index' },
        component: () => import('@/components/settings/users/SettingUsers.vue'),
      },
      {
        path: 'users/:id',
        name: 'show-user',
        meta: { permission: 'user.show' },
        component: () =>
          import('@/components/settings/users/SettingViewUser.vue'),
      },
      {
        path: 'create-user',
        name: 'create-user',
        meta: { permission: 'user.store' },
        component: () =>
          import('@/components/settings/users/SettingCreateUser.vue'),
      },
      {
        path: 'data',
        name: 'settings-data',
        meta: { permission: 'costreport.index' },
        component: () => import('@/components/settings/SettingData.vue'),
      },
      {
        path: 'filters',
        name: 'settings-filters',
        meta: { permission: 'product.store' },
        component: () => import('@/components/settings/SettingFilters.vue'),
      },
      {
        path: 'application',
        name: 'settings-application',
        meta: { permission: 'product.store' },
        component: () => import('@/components/settings/SettingApplication.vue'),
      },
      {
        path: 'integrations',
        name: 'settings-integrations',
        meta: { permission: 'company.update' },
        component: () => import('@/views/IntegrationsView.vue'),
        children: [
          {
            path: '',
            name: 'settings-integrations-index',
            meta: { permission: 'company.update' },
            component: () =>
              import('@/components/settings/SettingIntegrations.vue'),
          },
          {
            path: 'jira-channels',
            name: 'settings-integrations-jira-channels',
            meta: { permission: 'company.update' },
            component: () =>
              import('@/components/settings/integrations/JiraChannels.vue'),
          },
        ],
      },
    ],
  },
]
