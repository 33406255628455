import { useSessionClear } from '@/composables/session'

export default class HttpClient {
  headers = {
    Authorization: '',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  constructor() {
    this.refreshToken()
  }

  refreshToken() {
    this.headers['Authorization'] =
      'Bearer ' + localStorage.getItem('api_token')
  }

  combineURLs(baseURL, relativeURL) {
    return relativeURL
      ? baseURL.replace(/\/+$/, '') + '/' + relativeURL.replace(/^\/+/, '')
      : baseURL
  }

  getURL(path, params = {}) {
    const url = new URL(this.combineURLs(process.env.VUE_APP_API_URL, path))
    for (const [key, value] of Object.entries(params)) {
      url.searchParams.append(key, value)
    }

    return url
  }

  async request(config) {
    const url = this.getURL(config.url, config.params)
    const payload = {
      method: config.method,
      headers: { ...this.headers, ...config.headers },
    }

    if (config.data && Object.keys(config.data).length > 0) {
      payload.body = JSON.stringify(config.data)
    }
    const response = await fetch(url, payload)

    let data
    if (config.responseType === 'blob') {
      data = await response.blob()
    } else {
      data = await response.json()
    }

    if (!response.ok) {
      if (response.status === 401 && data['message'] === 'Invalid token') {
        await useSessionClear()
      }
    }

    return data
  }
}
