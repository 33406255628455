<template>
  <span
    class="toggle-wrapper"
    role="checkbox"
    :aria-checked="active"
    tabindex="0"
    @click="emit('click')"
  >
    <span
      class="toggle-background"
      :class="{
        'gold-mid': active,
        'gray-lighter': !active,
      }"
      :style="{
        backgroundColor: active ? primaryColor : undefined,
      }"
    />
    <span class="toggle-indicator" :style="indicatorStyles()" />
  </span>
</template>

<script setup>
import { computed, defineProps, getCurrentInstance } from 'vue'

const instance = getCurrentInstance().proxy
const primaryColor = computed(() => {
  return instance.$vuetify.theme.themes.light.primary
})

const props = defineProps({
  active: Boolean,
})

const emit = defineEmits(['click'])

const indicatorStyles = () => ({
  transform: props.active ? 'translateX(14px)' : 'translateX(0)',
})
</script>

<style scoped>
.gold-mid {
  background-color: #666666;
}

.gray-lighter {
  background-color: #c2c2c2;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 18px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
}
</style>
