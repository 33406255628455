import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import store from '@/store'

Vue.use(Vuetify)

const baseLight = {
  backgroundColor: '#FBFBFB',
  accent: '#FBFBFB',
  bgPage: '#fafafa',
  bgCard: '#ffffff',
  bgPageContrast: '#FFFFFF',
  greyLighten: '#F2F2F2',
  greyBlue: '#b3d1e8',
  greyMediumLighten: '#C6C6C6',
  greyMediumDarken: '#959595',
  greyDarken: '#535353',
  green: '#4caf50',
  black: '#303233',
  acidGreen: '#64E572',
  secondary: '#95C11F',
  chartGreen: '#39d463',
  checkGreen: '#27c432',
  success: '#06A21E',
  turquoiseLight: '#6AF9C4',
  turquoise: '#24CBE5',
  chartLegendHover: '#66a7ed',
  chartLegendBlue: '#348ceb',
  azureColor: '#008ad7',
  //primary: '#2283c5',
  primary: '#0070d9',
  royalBlue: '#1976d2',
  blueDarken: '#11649D',
  nightBlue: '#062038',
  googleColor: '#e34537',
  danger: '#FF0000',
  redDarken: '#E7314D',
  oracleColor: '#ea1f26',
  yellowWeak: '#FFF263',
  yellowAcid: '#DDDF00',
  yellow: '#FFD64D',
  yellowPeach: '#FF9655',
  yellowDarken: '#FFB100',
  awsColor: '#f79400',
  buttonLabel: '#676777',
  chartBackground: '#FFFFFF',
  greyBackground: '#F5F5F5',
  scaleway: '#4F0599',
  navbarColor: '#FFFFFF',
}
export const updateLight = () => {
  const theme = store.getters.theme
  Object.entries(theme).forEach(([key, value]) => {
    if (key in baseLight) {
      baseLight[key] = value
    }
  })
  return baseLight
}
export default new Vuetify({
  defaultAssets: { font: false },
  theme: {
    themes: {
      light: updateLight(),
      dark: {
        bgPage: '#1e1e1e',
        bgCard: '#1e1e1e',
        bgPageContrast: '#1e1e1e',
        greyLighten: '#F2F2F2',
        greyBlue: '#b3d1e8',
        greyMediumLighten: '#C6C6C6',
        greyMediumDarken: '#959595',
        greyDarken: '#535353',
        black: '#303233',
        green: '#4caf50',
        acidGreen: '#64E572',
        secondary: '#95C11F',
        chartGreen: '#39d463',
        checkGreen: '#27c432',
        success: '#06A21E',
        turquoiseLight: '#6AF9C4',
        turquoise: '#24CBE5',
        chartLegendHover: '#66a7ed',
        chartLegendBlue: '#348ceb',
        azureColor: '#008ad7',
        oracleColor: '#ea1f26',
        primary: '#0070d9',
        royalBlue: '#1976d2',
        blueDarken: '#11649D',
        nightBlue: '#062038',
        googleColor: '#e34537',
        danger: '#FF0000',
        redDarken: '#E7314D',
        yellowWeak: '#FFF263',
        yellowAcid: '#DDDF00',
        yellow: '#FFD64D',
        yellowPeach: '#FF9655',
        yellowDarken: '#FFB100',
        awsColor: '#f79400',
        buttonLabel: '#b4b4ad',
        chartBackground: '#1e1e1e',
        greyBackground: '#1e1e1e',
        scaleway: '#4F0599',
        navbarColor: '#1e1e1e',
      },
    },
  },
})
