<template>
  <v-app v-if="!loading">
    <router-view></router-view>
  </v-app>
</template>

<script setup>
import { computed, getCurrentInstance, ref, watch } from 'vue'
import moment from 'moment/moment'

const instance = getCurrentInstance().proxy
const store = computed(() => instance.$store)
const lang = computed(() => instance.$lang)
const isAuth = computed(() => store.value.getters.isAuth)
const userLang = computed(() => store.value.getters.user.lang)
const loading = ref(false)

async function setThemes() {
  const theme = await store.value.dispatch('getTheme')

  if (!theme || Object.keys(theme).length === 0) return

  instance.$vuetify.theme.themes.light = theme

  // TODO: fix this, only set primary color and secondary color as of now
  instance.$vuetify.theme.themes.dark.primary = theme.primary
  instance.$vuetify.theme.themes.dark.secondary = theme.secondary
}

watch(
  isAuth,
  async (isAuthenticated) => {
    if (!isAuthenticated) return
    loading.value = true

    await setThemes()

    loading.value = false
    lang.value.setLang(userLang.value)
    moment.locale(userLang.value)
  },
  { immediate: true },
)
</script>
