<template>
  <v-dialog
    v-model="editUser"
    max-width="660px"
    overlay-opacity="0.9"
    style="margin-top: 150px"
    content-class="my-custom-dialog"
    @click:outside="$emit('close')"
  >
    <v-card
      class="ma-auto pb-2"
      :style="{
        borderBottom: `6px solid ${primaryColor}`,
        borderRadius: '8px',
      }"
    >
      <v-card-title class="font-weight-bold font-mangueira-m">
        <span>{{ $lang.viewers.editUserModal.title }}</span>
        <v-spacer></v-spacer>
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </v-card-title>
      <v-card-subtitle class="font-mangueira">
        {{ $lang.viewers.editUserModal.subTitle }}
      </v-card-subtitle>
      <div class="row justify-center px-0 ma-0">
        <div class="col-11">
          <div class="d-flex flex-column align-center justify-center py-4 pt-0">
            <a
              target="_blank"
              :href="$lang.link.gravatar"
              class="font-mangueira-m px-1 py-3 text-decoration-underline text--primary avatar-container"
              style="font-size: 14px"
            >
              <div class="avatar-container text-center pb-1">
                <v-avatar size="80" :href="$lang.link.gravatar">
                  <v-gravatar :email="user.email"></v-gravatar>
                  <div class="overlay">
                    <v-icon large color="white">mdi-pencil-outline</v-icon>
                  </div>
                </v-avatar>
              </div>
              {{ $lang.link.createAGravatar }}
            </a>
          </div>
          <div class="row">
            <div class="col-6">
              <v-text-field
                :label="$lang.input.firstname"
                v-model="user.firstname"
                outlined
                flat
                hide-details
                prepend-inner-icon="mdi-account"
                style="font-size: 14px"
                dense
                :rules="rules"
                @input="handleChange"
              />
            </div>
            <div class="col-6 pb-0">
              <v-text-field
                :label="$lang.input.lastname"
                v-model="user.lastname"
                outlined
                flat
                hide-details
                prepend-inner-icon="mdi-account"
                style="font-size: 14px"
                dense
                :rules="rules"
                @input="handleChange"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-6 pb-0">
              <v-text-field
                :label="$lang.input.email"
                v-model="user.email"
                outlined
                disabled
                flat
                hide-details
                prepend-inner-icon="mdi-email"
                style="font-size: 14px"
                dense
                :rules="rules"
              />
            </div>
            <div class="col-6 pb-0">
              <v-select
                :label="$lang.input.language"
                dense
                outlined
                return-object
                item-text="name"
                prepend-inner-icon="mdi-flag"
                :items="Object.values(languages)"
                v-model="lang"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    v-bind="attrs"
                    @click="langSelected(item)"
                    v-on="on"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </div>
          <div class="row pt-0">
            <div class="col-12 pt-0 d-flex align-center justify-center">
              <v-btn
                color="primary"
                v-if="dataChanged"
                @click="updateUser"
                class="elevation-0"
              >
                <span
                  class="font-mangueira-m"
                  style="font-size: 16px; color: white; letter-spacing: 0"
                >
                  {{ $lang.button.validate }}
                </span>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, getCurrentInstance, ref, toRefs, watch } from 'vue'
import moment from 'moment/moment'
import User from '@/services/user'

const instance = getCurrentInstance().proxy
const $store = computed(() => instance.$store)
const $lang = computed(() => instance.$lang)
const primaryColor = computed(() => {
  return instance.$vuetify.theme.themes.light.primary
})

const user = ref({
  firstname: $store.value.getters.user.firstname,
  lastname: $store.value.getters.user.lastname,
  email: $store.value.getters.user.email,
  lang: $store.value.getters.user.lang,
  id: $store.value.getters.user.id,
})

const languages = {
  fr: { name: 'Français', code: 'fr' },
  en: { name: 'English', code: 'en' },
}

const lang = ref(languages[$store.value.state.user.lang])
const langSelect = ref($store.value.state.user.lang)

const rules = ref([(v) => !!v])
const EmptyField = ref(false)
const dataChanged = ref(false)

const emit = defineEmits(['close'])
const props = defineProps({
  editUser: Boolean,
})

const { editUser } = toRefs(props)

const propertiesForWatch = computed(() => {
  return [user.value, lang.value]
})

function handleChange() {
  const isNameChanged =
    user.value.firstname !== $store.value.getters.user.firstname ||
    user.value.lastname !== $store.value.getters.user.lastname
  const isLangChanged = langSelect.value !== $store.value.state.user.lang

  dataChanged.value = isNameChanged || isLangChanged
}

function langSelected(item) {
  if ($store.value.state.user.lang === item.code) {
    langSelect.value = item.code
  } else {
    langSelect.value = item.code
  }
  handleChange()
}

async function updateUser() {
  const UserService = new User()

  const payload = {
    firstname: user.value.firstname,
    lastname: user.value.lastname,
    email: user.value.email,
    lang: langSelect.value,
  }

  const response = await UserService.update(user.value.id, payload)
  if (response.code === 200) {
    const updatedInfo = [
      { info: 'firstname', value: user.value.firstname },
      { info: 'lastname', value: user.value.lastname },
      { info: 'lang', value: langSelect.value },
    ]

    updatedInfo.forEach((item) => {
      $store.value.commit('setUserInfo', item)
    })

    dataChanged.value = false
    emit('close')

    $lang.value.setLang(langSelect.value)
    moment.locale(langSelect.value)
  }
}

watch(
  propertiesForWatch,
  () => {
    EmptyField.value = propertiesForWatch.value.some((field) =>
      Object.values(field).some((value) => value === ''),
    )
  },
  { deep: true },
)
</script>

<style scoped>
>>> .my-custom-dialog {
  top: -100px;
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 1;
}

.avatar-container:hover .overlay {
  opacity: 1;
  cursor: pointer;
}
</style>
